import type {
  ScoreboardResponse,
  ScoreboardResponseRelatedScheduleResponseRelatedPagedData,
} from '@on3/api';
import { useSite } from '@on3/ui-lib/index';
import { getUserTimeZone } from '@on3/ui-lib/utils/date';
import { SidebarContainer } from 'components/UI/Layout/Containers/Sidebar/SidebarContainer/SidebarContainer';
import { isBefore, startOfDay } from 'date-fns';
import Link from 'next/link';
import { slugify } from 'utils/slugify';

import { ScheduleModuleItem } from './Item';
import styles from './ScheduleModule.module.scss';

const VISIBLE_GAMES = 10;

export const ScheduleModule = ({
  list,
  relatedModel,
}: ScoreboardResponseRelatedScheduleResponseRelatedPagedData) => {
  const { currentSite } = useSite();

  const showFullSchedule =
    !!relatedModel?.organization?.slug &&
    !!relatedModel?.sport?.name &&
    !!relatedModel.year;
  const sluggedSport = slugify(relatedModel?.sport?.name || 'football');
  const urlSchedules = `/college/${relatedModel?.organization?.slug}/${sluggedSport}/${relatedModel?.year}/schedule/`;
  const userTimeZone = getUserTimeZone();
  const orgLinkColor =
    currentSite?.linkColor ??
    relatedModel?.organization?.primaryColor ??
    '#000';

  let gameList = list;

  // We only want to show x number of games for basketball
  if (relatedModel?.sport?.key === 2) {
    const today = startOfDay(new Date());

    const pastGames: ScoreboardResponse[] = [];
    const futureGames: ScoreboardResponse[] = [];

    gameList?.forEach((game) => {
      const gameDate = new Date((game?.startDateUtc || 0) * 1000);

      if (isBefore(gameDate, today)) {
        pastGames.push({ ...game });
      } else {
        futureGames.push({ ...game });
      }
    });

    // Below will insure we always show x number of games on the home module.
    if (pastGames.length < 5) {
      const sliceNumber = VISIBLE_GAMES - pastGames.length;

      gameList = [...pastGames, ...futureGames.slice(0, sliceNumber)];
    } else if (futureGames.length < 5) {
      const sliceNumber = VISIBLE_GAMES - futureGames.length;

      gameList = [...pastGames.slice(-sliceNumber), ...futureGames];
    } else {
      gameList = [...pastGames.slice(-5), ...futureGames.slice(0, 5)];
    }
  }

  return (
    <article className={styles.block}>
      <h3>{relatedModel?.sport?.name} Schedule</h3>
      <ol className={styles.schedule}>
        {gameList?.map((game) => (
          <ScheduleModuleItem
            game={game}
            key={game?.key}
            sport={relatedModel?.sport?.name || 'football'}
          />
        ))}
      </ol>
      <div className={styles.footer}>
        {showFullSchedule && (
          <Link
            className={styles.link}
            href={urlSchedules}
            style={{ color: orgLinkColor }}
          >
            Full Schedule
          </Link>
        )}
        {userTimeZone && (
          <span className={styles.disclaimer}>All Times Eastern</span>
        )}
      </div>
    </article>
  );
};
